import { useState } from 'react';
import { FormControl, Stack, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import NameField from '@/components/forms/new/formikFormFields/NameField';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';

const AdminUsersLastName = ({ darkMode }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack direction={'row'} spacing={stackSpacing} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>Last Name</FormLabel>
                <Field
                    component={NameField}
                    id="lastName"
                    name="lastName"
                    walkthroughid={walkthroughIds.adminsGeneral.ufLastName}
                />
                {isHovered && (
                    <FieldToolTip
                        label="Enter Admin User's last name"
                        ariaLabel="admin-user-last-name-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default AdminUsersLastName;
