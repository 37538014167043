import { Box, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { dropDownFormStyle } from '../formStyles';
import { forwardRef } from 'react';
import { useGetAdminPermission } from './queries';

const ChakraSelect = forwardRef((props, ref) => (
    <Select selectedOptionColor="brand" ref={ref} {...props} />
));
ChakraSelect.displayName = 'ChakraSelect';

const PermissionsSetDropDown = ({
    field,
    form: { setValues, values, validateField, touched, setTouched },
    ...props
}) => {
    const { data: permissionSets, status } = useGetAdminPermission(
        props.currentCompany,
        props.accountScope,
    );

    return (
        <Box
            flex="1"
            sx={{
                paddingTop: '15px',
            }}>
            <VStack width={'100%'} alignItems={'normal'}>
                <ChakraSelect
                    {...field}
                    {...props}
                    disabled={status === 'loading'}
                    minWidth={'450px'}
                    data-walkthroughid={props.walkthroughid}
                    value={permissionSets?.find(
                        (item) => item.id === props.permissionSetID,
                    )}
                    options={permissionSets?.sort(function (a, b) {
                        var nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    })}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    chakraStyles={dropDownFormStyle(props.darkMode)}
                    onChange={(item) => {
                        props.handleSelectChange(item, 'permissionSetID');
                        setValues({
                            ...values,
                            permissionSetID: item.id,
                        }).then(() => {
                            validateField('permissionSetID');
                        });
                    }}
                    onBlur={() => {
                        setTouched({
                            ...touched,
                            permissionSetID: true,
                        });
                    }}
                />
            </VStack>
        </Box>
    );
};

export default PermissionsSetDropDown;
