import {
    Box,
    VStack,
    FormErrorMessage,
    InputRightElement,
} from '@chakra-ui/react';
import { InputGroup } from '@/components/v4';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { chakraDatepicker, chakraDatepickerDarkMode } from '@/constants';
import { CalendarIcon } from '@chakra-ui/icons';
import {
    ChakraDatePickerDarkModeStyles,
    ChakraDatePickerStyles,
} from '@/chakratheme';
import { borderColor } from '../formStyles';

const DatePicker = ({ field, form: { errors }, ...props }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-start',
                paddingTop: '15px',
            }}>
            <VStack width={'100%'} alignItems={'normal'}>
                <InputGroup
                    sx={
                        props.darkMode
                            ? chakraDatepickerDarkMode
                            : chakraDatepicker
                    }
                    data-walkthroughid={props.walkthroughid}>
                    <SingleDatepicker
                        {...field}
                        {...props}
                        disabled={props.isLoading}
                        minDate={new Date()}
                        name="date-input"
                        date={props.date}
                        onDateChange={(value) => {
                            props.changeHandler('expirationDate', value);
                        }}
                        configs={{
                            dateFormat: 'ccc, dd MMM, yyyy zzz',
                        }}
                        propsConfigs={{
                            ...(props.darkMode
                                ? ChakraDatePickerDarkModeStyles
                                : ChakraDatePickerStyles),
                            inputProps: {
                                height: '30px',
                                borderColor: props.darkMode
                                    ? borderColor
                                    : 'gray.200',
                            },
                        }}
                    />
                    <InputRightElement
                        height="30px"
                        zIndex={0}
                        color={props.darkMode ? 'white' : 'gray.500'}
                        pointerEvents="none">
                        <CalendarIcon />
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage
                    display="flex"
                    sx={{ marginTop: '0 !important', fontSize: '10px' }}
                    justifyContent="flex-end">
                    {errors[field.name]}
                </FormErrorMessage>
            </VStack>
        </Box>
    );
};
export default DatePicker;
