import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { Flex, Icon, Skeleton, useDisclosure } from '@chakra-ui/react';

import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@/components/v4';
import ModalCloseButton from '@/components/v4/ModalCloseButton';
import { axios } from '@/services/axios';
import { useSingleToast } from 'hooks/useSingleToast';
import DeallocateCompaniesVgModal from './DeallocateCompaniesVgModal';
import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import walkthroughIds from '../../walkthroughIds';

function DeleteVgModal({ isOpen, onOpen, onClose, vg }) {
    const {
        isOpen: isConfirmationOpen,
        onOpen: onConfirmationOpen,
        onClose: onConfirmationClose,
    } = useDisclosure();

    const { successToast, errorToast } = useSingleToast();

    const [isDeleting, setIsDeleting] = useState(false);

    const { id: adminID } = useSelector((state) => {
        return { ...state.login };
    });
    const {
        visibilityGroups: {
            modal: { ufRemoveButton, ufDeleteButton },
        },
    } = walkthroughIds;

    const queryClient = useQueryClient();

    const { data: visibilityGroup, isFetching: visibilityGroupLoading } =
        useQuery({
            queryKey: ['visibilityGroup', adminID],
            queryFn: async () => {
                const res = await axios.get(`/visibilitygroup/${vg?.id}`);
                return res.data;
            },
            enabled: isOpen,
            staleTime: 0,
            refetchOnWindowFocus: false,
        });

    const deleteVg = async () => {
        setIsDeleting(true);
        try {
            await axios.delete(`/visibilitygroup/${vg.id}`);
            successToast('Successfully deleted visibility group!');
            onClose();
            queryClient.invalidateQueries({ queryKey: ['visibilityGroups'] });
        } catch (e) {
            errorToast(
                e?.response?.data?.message ||
                    'Unable to delete visibility group.',
            );
        } finally {
            setIsDeleting(false);
        }
    };
    return (
        <>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
                closeOnOverlayClick={true && !isDeleting}>
                <ModalOverlay />
                <ModalContent
                    as="form"
                    data-walkthroughid={'visibilityGroupModal.deleteModal'}>
                    <ModalHeader>Delete Visibility Group</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {visibilityGroupLoading ? (
                            <Skeleton mt={1} w={200} h={8} ml={1} />
                        ) : (
                            <Flex justifyContent="space-between">
                                <Flex align="center">
                                    <Icon
                                        as={
                                            visibilityGroup?.assignedCompanies >
                                            0
                                                ? FaCircleXmark
                                                : FaCircleCheck
                                        }
                                        color={
                                            visibilityGroup?.assignedCompanies >
                                            0
                                                ? 'red.500'
                                                : 'green.500'
                                        }
                                        boxSize="18px"
                                    />
                                    <Text mx="2">
                                        {visibilityGroup?.assignedCompanies > 0
                                            ? `Remove Visibility Group from ${visibilityGroup?.assignedCompanies} Companies?`
                                            : 'Visibility Group is not in use'}
                                    </Text>
                                </Flex>
                                {visibilityGroup?.assignedCompanies > 0 && (
                                    <>
                                        {visibilityGroupLoading ? (
                                            <Skeleton mt={1} h={10} ml={1} />
                                        ) : (
                                            <Button
                                                minW="100px"
                                                colorScheme="red"
                                                onClick={() => {
                                                    onConfirmationOpen();
                                                    onClose();
                                                }}
                                                data-walkthroughid={
                                                    ufRemoveButton
                                                }>
                                                Remove
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Flex>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            colorScheme="red"
                            minW="100px"
                            isLoading={isDeleting}
                            isDisabled={visibilityGroup?.assignedCompanies > 0}
                            onClick={deleteVg}
                            data-walkthroughid={ufDeleteButton}>
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <DeallocateCompaniesVgModal
                onDeleteOpen={onOpen}
                isOpen={isConfirmationOpen}
                onClose={onConfirmationClose}
                vg={visibilityGroup}
            />
        </>
    );
}

export default DeleteVgModal;
