import { AlertTitle, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect, useLoaderData } from 'react-router-dom';

import { visibilityGroupsQuery } from '@tcap/data-router/loaders';
import { ACTIONS, useVisibilityGroup } from 'context/VisibilityGroupsContext';

import { loadingColumnsConstructor } from '@/components/tables/ReduxTable';
import { Alert, AlertIcon, Text } from '@/components/v4';
import MaterialTable from '@/components/v4/MaterialTable';
import { AddVgModal, DeleteVgModal, LeaveVgModal } from './modal/';
import {
    defaultColumns,
    dummyTable,
    TableActions,
    TableHeader,
    useTableActions,
} from './table/';
import walkthroughIds from '../walkthroughIds';

function VisibilityGroupsIndex() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isDeleteVgModalOpen,
        onOpen: onDeleteVgModalOpen,
        onClose: onDeleteVgModalClose,
    } = useDisclosure();
    const {
        isOpen: isLeaveVgModalOpen,
        onOpen: onLeaveVgModalOpen,
        onClose: onLeaveVgModalClose,
    } = useDisclosure();
    const tableRef = useRef();
    const initialData = useLoaderData();
    const dispatch = useDispatch();
    const [, dispatchVG] = useVisibilityGroup();
    const [vg, setVg] = useState(null);
    const { requiredScope } = useSelector((state) => state.login);
    const { currentCompany } = useSelector((state) => state.navigationLists);
    const {
        visibilityGroups: { ufEditButton, ufDeleteButton, ufLeaveButton },
    } = walkthroughIds;

    const {
        data: visibilityGroupsData,
        isRefetching,
        isLoading,
        refetch,
        error,
    } = useQuery({
        ...visibilityGroupsQuery(),
        onSuccess: async (data) => {
            dispatchVG({
                type: ACTIONS.FETCH,
                payload: data,
            });

            dispatch({
                type: 'CHANGE_TABLE_LOADING',
            });
            return data;
        },
        enabled: requiredScope >= 40,
        staleTime: 500,
        initialData,
    });

    const loadingColumns = useMemo(
        () => loadingColumnsConstructor(defaultColumns),
        [],
    );
    const componentLoadingState = isLoading || isRefetching;
    const renderedColumns = componentLoadingState
        ? loadingColumns
        : defaultColumns;

    const renderedTableData = componentLoadingState
        ? dummyTable
        : visibilityGroupsData;
    const { handleDeleteVg, handleLeaveVg, handleEditBtn } = useTableActions({
        onDeleteVgModalOpen,
        onLeaveVgModalOpen,
        setVg,
    });

    const tableActions = TableActions({
        tableRef,
        refetch,
        ufEditButton,
        ufDeleteButton,
        ufLeaveButton,
        useTableActions: { handleDeleteVg, handleLeaveVg, handleEditBtn },
    });

    if (requiredScope < 40) {
        throw redirect('/dashboard-services');
    }

    if (!currentCompany) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle>
                    No Partner / Company / Wholesaler Selected
                </AlertTitle>
                <Text>Please select a wholesaler and a partner / company.</Text>
            </Alert>
        );
    }

    return (
        <section aria-label="visibilityGroups table">
            <h1 className="mb-2 text-base font-medium">Visibility Groups</h1>
            {error?.message ? (
                <Alert status="error">
                    <AlertIcon />
                    <Text>
                        {(error?.message?.includes('401') &&
                            'Unauthorized. Please try again later') ||
                            error?.message ||
                            'This feature is not enabled for your profile. Please contact your wholesaler'}
                    </Text>
                </Alert>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    actions={tableActions}
                    columns={renderedColumns}
                    data={renderedTableData}
                    title={<TableHeader onClick={onOpen} />}
                />
            )}
            <AddVgModal onClose={onClose} isOpen={isOpen} />
            <DeleteVgModal
                onClose={onDeleteVgModalClose}
                onOpen={onDeleteVgModalOpen}
                isOpen={isDeleteVgModalOpen}
                vg={vg}
            />
            <LeaveVgModal
                onClose={onLeaveVgModalClose}
                isOpen={isLeaveVgModalOpen}
                vg={vg}
            />
        </section>
    );
}

export default VisibilityGroupsIndex;
