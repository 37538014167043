import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initialState } from '../constants';
import walkthroughIds from '../../walkthroughIds';
import { useNavigate } from 'react-router-dom';
import { generateRandomString } from '@/utils/utils';
import { useCreateNewAdminUser, useResetPassword } from './queries';
import FormBase from '@/components/forms/new/FormBase';
import AdminUsersFirstName from './formFields/AdminUsersFirstName';
import AdminUsersLastName from './formFields/AdminUsersLastName';
import AdminUsersEmail from './formFields/AdminUsersEmail';
import AdminUsersPermissionSetDropdown from './formFields/AdminUsersPermissionSetDropdown';
import AdminUsersResetMFA from './formFields/AdminUsersResetMFA';
import AdminUsersGlobalSwitches from './formFields/AdminUsersAPIDocReaderSwitch';
import { onModalError } from 'components/tables/TableAndModal';

const AdminsCreate = () => {
    const { ufBackBtn, ufSaveBtn } = walkthroughIds.adminsGeneral;

    const { darkMode } = useSelector((state) => state.settings);

    const navigate = useNavigate();

    const { requiredScope: accountScope } = useSelector((state) => state.login);

    const { currentCompany, currentCompanyData } = useSelector((state) => {
        return { ...state.navigationLists };
    });

    const [adminUser, setAdminUser] = useState({
        ...initialState,
        password: generateRandomString(),
    });

    const [disableMFA, setDisableMFA] = useState(false);

    const dispatch = useDispatch();

    const handleSuccess = () => {
        dispatch({ type: 'RESET_MODAL' });
        dispatch({ type: 'RESET_TABLE' });
    };

    const handleFormSwitchChange = (e) => {
        const { name, checked } = e.target;
        setAdminUser((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    useEffect(() => {
        if (currentCompanyData?.mfaEnforced) {
            setAdminUser((prev) => ({
                ...prev,
                mfaEnabled: true,
            }));
            setDisableMFA(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompanyData?.mfaEnforced]);

    const { mutate: resetPassword } = useResetPassword(
        adminUser,
        handleSuccess,
        onModalError,
    );

    const { mutate: createNewUser } = useCreateNewAdminUser(
        adminUser,
        navigate,
        resetPassword,
        currentCompany,
    );

    const breadCrumbTrail = [
        { label: 'Admin Accounts', path: ['/admins', { index: 0 }] },
        { label: 'Add User', path: '' },
    ];
    const header = 'User Details';

    const finalFormSubmit = async (value) => {
        const finalSubmit = {
            ...value,
            permissionSetID: adminUser?.permissionSetID,
            mfaEnabled: adminUser?.mfaEnabled,
            canOverrideOverStamping: adminUser?.canOverrideOverStamping,
            apiDocReader: adminUser?.apiDocReader,
            isActive: adminUser?.isActive,
            password: adminUser?.password,
            companyId: currentCompany,
        };

        setAdminUser(finalSubmit);

        createNewUser();
    };

    const formFields = (errors, touched, values) => {
        const fields = [
            <AdminUsersFirstName
                key="firstName"
                errors={errors}
                touched={touched}
                darkMode={darkMode}
            />,
            <AdminUsersLastName key="lastName" darkMode={darkMode} />,
            <AdminUsersEmail
                key="email"
                errors={errors}
                touched={touched}
                darkMode={darkMode}
            />,
            <AdminUsersPermissionSetDropdown
                key="manageAccess"
                adminUser={adminUser}
                permissionSetID={adminUser?.permissionSetID}
                currentCompany={currentCompany}
                accountScope={accountScope}
                setAdminUser={setAdminUser}
                errors={errors}
                touched={touched}
                darkMode={darkMode}
                values={values}
            />,
            <AdminUsersResetMFA
                key={'resetMFA'}
                adminUser={adminUser}
                handleFormSwitchChange={handleFormSwitchChange}
                darkMode={darkMode}
                isCreate={true}
                disableMFA={disableMFA}
            />,
            <AdminUsersGlobalSwitches
                key={'globalSwitches'}
                accountScope={accountScope}
                adminUser={adminUser}
                handleFormSwitchChange={handleFormSwitchChange}
                darkMode={darkMode}
            />,
        ];

        return fields;
    };

    return (
        <FormBase
            breadCrumbTrail={breadCrumbTrail}
            header={header}
            initialValues={initialState}
            finalFormSubmit={finalFormSubmit}
            backlink={['/admins', { state: { index: 0 } }]}
            ufBackBtn={ufBackBtn}
            ufSaveBtn={ufSaveBtn}>
            {({ errors, touched, values }) =>
                formFields(errors, touched, values)
            }
        </FormBase>
    );
};

export default AdminsCreate;
