/* eslint-disable no-unused-vars */
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Stack,
    StackDivider,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { postVg } from '@/API/VisibilityGroups';
import { ACTIONS, useVisibilityGroup } from 'context/VisibilityGroupsContext';

import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@/components/v4';
import ModalCloseButton from '@/components/v4/ModalCloseButton';
import { useSingleToast } from 'hooks/useSingleToast';
import walkthroughIds from '../../walkthroughIds';

function AddVgModal({ isOpen, onClose }) {
    const [visibilityGroups, dispatch] = useVisibilityGroup();
    const [vg, setVg] = useState(null);
    const navigate = useNavigate();
    const initialValues = {
        name: '',
        description: '',
    };
    const { currentPartner } = useSelector((state) => state.navigationLists);
    const {
        visibilityGroups: { ufNameInput, ufDescriptionInput, ufSaveButton },
    } = walkthroughIds;
    const queryClient = useQueryClient();

    const { errorToast } = useSingleToast();

    const { mutate: createVg, reset } = useMutation(
        async (newGroup) => {
            const response = await postVg(newGroup);
            return response.data;
        },
        {
            onSuccess: async (data) => {
                dispatch({
                    type: ACTIONS.ADDED,
                    payload: data,
                });
                setVg(data);

                queryClient.invalidateQueries({
                    queryKey: ['visibilityGroups'],
                });
                toast.success('Visibility Group created successfully');
                onClose();
                navigate(`${data?.id}/edit`);
            },
            onError: (error) => {
                errorToast(error?.response?.data?.message);
            },
        },
    );

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const newVisibilityGroup = {
                description: values.description,
                name: values.name,
                partnerID: currentPartner,
            };

            createVg(newVisibilityGroup);
        } catch (error) {
            throw new Error(
                error?.message || 'An error occurred. Please try again later.',
            );
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <Modal
            closeOnOverlayClick={true}
            isOpen={isOpen}
            onClose={() => {
                onClose();
                // below is to remove previous error(s)
                reset();
            }}
            size="2xl">
            <ModalOverlay />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ handleSubmit, errors, touched }) => (
                    <ModalContent
                        as="form"
                        onSubmit={handleSubmit}
                        data-walkthroughid={
                            'visibilityGroupModal.modalContent'
                        }>
                        <ModalHeader>{'New Visibility Group'}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack spacing="5">
                                <Stack
                                    spacing="5"
                                    divider={
                                        <StackDivider
                                            borderColor="#F5F5F5"
                                            className="my-[20px]"
                                        />
                                    }>
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            !!errors.name && touched.name
                                        }>
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            spacing={{ base: '1.5', md: '8' }}
                                            justify="space-between">
                                            <FormLabel
                                                variant="inline"
                                                htmlFor="name"
                                                minW="100px"
                                                fontSize="sm">
                                                Name
                                            </FormLabel>
                                            <Field
                                                as={Input}
                                                id="name"
                                                name="name"
                                                type="text"
                                                maxW={{ md: '3xl' }}
                                                fontSize="sm"
                                                validate={(value) => {
                                                    let error;
                                                    if (value.length < 1) {
                                                        error =
                                                            'Name is required';
                                                    }
                                                    return error;
                                                }}
                                                data-walkthroughid={ufNameInput}
                                            />
                                        </Stack>

                                        <Flex
                                            justifyContent="flex-start"
                                            ms={8}>
                                            <Flex minW="100px" aria-hidden />

                                            <FormErrorMessage>
                                                {errors.name}
                                            </FormErrorMessage>
                                        </Flex>
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        isInvalid={
                                            !!errors.description &&
                                            touched.description
                                        }>
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            spacing={{ base: '1.5', md: '8' }}
                                            justify="space-between">
                                            <FormLabel
                                                variant="inline"
                                                htmlFor="description"
                                                minW="100px"
                                                fontSize="sm">
                                                Description
                                            </FormLabel>
                                            <Field
                                                as={Input}
                                                id="description"
                                                name="description"
                                                type="text"
                                                maxW={{ md: '3xl' }}
                                                fontSize="sm"
                                                validate={(value) => {
                                                    let error;
                                                    if (value.length < 1) {
                                                        error =
                                                            'Description is required';
                                                    }
                                                    return error;
                                                }}
                                                data-walkthroughid={
                                                    ufDescriptionInput
                                                }
                                            />
                                        </Stack>
                                        <Flex
                                            justifyContent="flex-start"
                                            ms={8}>
                                            <Flex minW="100px" aria-hidden />

                                            <FormErrorMessage>
                                                {errors.description}
                                            </FormErrorMessage>
                                        </Flex>
                                    </FormControl>
                                </Stack>
                            </Stack>{' '}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="submit"
                                data-walkthroughid={ufSaveButton}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                )}
            </Formik>
        </Modal>
    );
}

export default AddVgModal;
