import moment from 'moment';

const selectDate = new Date(
    moment().add(180, 'day').set({
        hour: 0,
        minute: 0,
        second: 0,
    }),
);

export const initialState = {
    name: '',
    isActive: true,
    permissionSetID: '',
    expiration: selectDate,
    expirationDate: selectDate,
    expirationTime: '00:00',
};
