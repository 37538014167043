/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import MaterialFilterList from '@material-ui/icons/FilterList';
import MaterialRefreshIcon from '@material-ui/icons/Refresh';
import { IoLayers } from 'react-icons/io5';
import { saveTableColumnOrder } from '@/components/v4/MaterialTable/helpers';
import CustomTableToolbar from 'components/tables/CustomTableToolbar';
import { MTableGroupbar } from 'material-table';
import isEqual from 'lodash/isEqual';

import { tableIcons, components } from './consts';

import walkthroughIds from './walkthroughIds';

const { ufFilterButton, ufRefreshButton, ufGroupButton } = walkthroughIds.table;

const FilterList = React.forwardRef((props, ref) => (
    <MaterialFilterList
        {...props}
        ref={ref}
        data-walkthroughid={ufFilterButton}
    />
));

export const RefreshIcon = React.forwardRef((props, ref) => (
    <MaterialRefreshIcon
        {...props}
        ref={ref}
        data-walkthroughid={ufRefreshButton}
    />
));

const GroupIcon = React.forwardRef((props, ref) => (
    <IoLayers {...props} ref={ref} data-walkthroughid={ufGroupButton} />
));

FilterList.displayName = 'FilterList';
RefreshIcon.displayName = 'RefreshIcon';
GroupIcon.displayName = 'GroupIcon';

/**
 * @param {import('material-table').MaterialTableProps} props
 */
export default function MaterialTableHoc({
    data,
    columns,
    actions = [],
    refreshFn,
    options = {},
    titleName,
    refreshTable,
    ...props
}) {
    const dispatch = useDispatch();
    const [isFiltering, setIsFiltering] = useState(false);
    const [isGrouping, setIsGrouping] = useState(false);
    const { darkMode } = useSelector((state) => state.settings);

    function toggleFiltering() {
        setIsFiltering((p) => !p);
    }

    function toggleGrouping() {
        setIsGrouping((p) => !p);
    }
    const titleNameOrder = titleName?.toLowerCase().replace(/\s/g, '');

    const { tableColumnOrder, ...restSettings } = useSelector((state) => {
        return { ...state.settings };
    });

    const defaultColumnsOrder = columns.map((column, index) => {
        return {
            [column.field]: {
                hidden:
                    column.hidden ||
                    (column.hidden === (undefined || null) && false),
                columnOrder: index,
                groupOrder: undefined,
            },
        };
    });

    const resetColumnSettings = useCallback(() => {
        dispatch({
            type: 'CHANGE_TABLE_COLUMN_ORDER',
            payload: {
                [titleNameOrder]: defaultColumnsOrder,
            },
        });

        saveTableColumnOrder(restSettings, {
            ...tableColumnOrder,
            [titleNameOrder]: defaultColumnsOrder,
        });

        refreshTable();
    }, [
        defaultColumnsOrder,
        dispatch,
        refreshTable,
        restSettings,
        tableColumnOrder,
        titleNameOrder,
    ]);

    // For if the table column settings doesn't exist
    const tableColumnInitialise = useCallback(() => {
        if (!tableColumnOrder) {
            dispatch({
                type: 'CHANGE_TABLE_COLUMN_ORDER',
                payload: {},
            });

            saveTableColumnOrder(restSettings, {
                ...tableColumnOrder,
                [titleNameOrder]: defaultColumnsOrder,
            });
        }
        if (
            !tableColumnOrder[titleNameOrder] ||
            tableColumnOrder[titleNameOrder] === undefined ||
            Object.keys(tableColumnOrder[titleNameOrder]).length === 0
        ) {
            resetColumnSettings();
        }
    }, [
        dispatch,
        restSettings,
        tableColumnOrder,
        titleNameOrder,
        defaultColumnsOrder,
        resetColumnSettings,
    ]);

    tableColumnInitialise();

    useEffect(() => {
        tableColumnInitialise();
    }, [
        dispatch,
        restSettings,
        tableColumnInitialise,
        tableColumnOrder,
        titleNameOrder,
    ]);

    const tableColumnSettings = tableColumnOrder[titleNameOrder];

    const columnsSettings = (columns) =>
        columns.map((column, index) => {
            return {
                [column.field]: {
                    hidden:
                        column.hidden || (column.hidden === undefined && false),
                    columnOrder:
                        column.tableData?.columnOrder ||
                        (column.tableData?.columnOrder === undefined && index),
                    groupOrder:
                        column.tableData?.groupOrder ||
                        (column.tableData?.groupOrder === undefined &&
                            undefined),
                },
            };
        });

    const dispatchColumnSettings = useCallback(
        (columns) => {
            dispatch({
                type: 'CHANGE_TABLE_COLUMN_ORDER',
                payload: {
                    [titleNameOrder]: columnsSettings(columns),
                },
            });
        },
        [dispatch, titleNameOrder],
    );

    if (!tableColumnOrder || !tableColumnSettings) {
        dispatchColumnSettings(columns);
    }

    const applyColumnSettings = (columns, columnSettings) => {
        return columns.map((column) => {
            const currentColumnSetting = Array.isArray(columnSettings)
                ? columnSettings.find((setting) => setting[column.field])?.[
                      column.field
                  ]
                : undefined;
            return {
                ...column,
                hidden: currentColumnSetting?.hidden,
                tableData: {
                    columnOrder: currentColumnSetting?.columnOrder,
                    groupOrder: currentColumnSetting?.groupOrder,
                },
            };
        });
    };

    const [previousColumns, setPreviousColumns] = useState(columns);
    const [currentColumns, setCurrentColumns] = useState(
        applyColumnSettings(columns, tableColumnSettings) || columns,
    );

    const initialGroupedColumns = currentColumns
        .filter((column) => column.tableData.groupOrder !== undefined)
        .map((column, index) => {
            return {
                [column.field]: {
                    hidden:
                        column.hidden ||
                        (column.hidden === (undefined || null) && false),
                    columnOrder: index,
                    groupOrder: undefined,
                },
            };
        });

    const [currentGroupedColumns, setCurrentGroupedColumns] = useState(
        initialGroupedColumns,
    );

    const setColumnOrder = useCallback(
        (columns) => {
            dispatchColumnSettings(columns);

            saveTableColumnOrder(restSettings, {
                ...tableColumnOrder,
                [titleNameOrder]: columnsSettings(columns),
            });
        },
        [
            dispatchColumnSettings,
            restSettings,
            tableColumnOrder,
            titleNameOrder,
        ],
    );

    useEffect(() => {
        if (columns !== currentColumns) {
            setCurrentColumns(
                applyColumnSettings(columns, tableColumnSettings),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    const saveColumnOrder = useCallback(
        (columns) => {
            if (columns !== previousColumns) {
                setPreviousColumns(columns);
                setColumnOrder(columns);
            }
        },
        [previousColumns, setColumnOrder],
    );

    const MATERIAL_TABLE_OPTIONS = {
        sorting: true,
        filtering: isFiltering,
        actionsColumnIndex: -1, // Makes actions last column
        pageSize: 50,
        pageSizeOptions: (4)[(50, 100, 250, 500)],
        maxBodyHeight: 696,
        addRowPosition: 'first',
        cellStyle: { textAlign: 'left', wordWrap: 'break-word' },
        columnsButton: true,
        emptyRowsWhenPaging: false,
        exportAllData: true,
        exportButton: { csv: true, pdf: true },
        exportFileName: 'export',
        grouping: isGrouping,
        headerStyle: {
            position: 'sticky',
            top: 0,
            background: darkMode ? '#121212' : 'white',
        },
        paging: true,
        search: true,
        searchAutoFocus: false,
        selection: undefined,
        showTextRowsSelected: false,
        //loadingType: 'linear',
    };

    const MATERIAL_TABLE_ACTIONS = [
        {
            icon: FilterList,
            tooltip: 'Filter',
            isFreeAction: true,
            onClick: toggleFiltering,
        },
        {
            icon: GroupIcon,
            tooltip: 'Group',
            isFreeAction: true,
            onClick: toggleGrouping,
        },
        {
            icon: RefreshIcon,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: refreshFn,
            hidden: !refreshFn,
        },
    ];

    const handleGroupingChange = (currentGroupBarColumns, groupColumns) => {
        setCurrentGroupedColumns(currentGroupBarColumns);
        const newColumns = [...currentColumns];
        newColumns.forEach((column) => {
            groupColumns.forEach((groupedColumn) => {
                if (column.field === groupedColumn.field) {
                    column.tableData.groupOrder =
                        groupedColumn.tableData.groupOrder;
                }
            });
        });
        saveColumnOrder(newColumns);
    };

    return (
        <MaterialTable
            icons={tableIcons}
            data={data}
            columns={currentColumns || columns}
            actions={[...MATERIAL_TABLE_ACTIONS, ...actions]}
            options={{ ...MATERIAL_TABLE_OPTIONS, ...options }}
            components={{
                ...components,
                Toolbar: (props) => {
                    const toolbarButtonAlignment =
                        props.selectedRows.length > 0
                            ? 'left'
                            : props.toolbarButtonAlignment;
                    return (
                        <CustomTableToolbar
                            {...props}
                            toolbarButtonAlignment={toolbarButtonAlignment}
                            resetColumnSettings={resetColumnSettings}
                        />
                    );
                },
                Groupbar: (props) => {
                    const groupbarColumns = columnsSettings(props.groupColumns);
                    useEffect(() => {
                        if (!isEqual(groupbarColumns, currentGroupedColumns)) {
                            handleGroupingChange(
                                groupbarColumns,
                                props.groupColumns,
                            );
                        }
                    }, [groupbarColumns, props.groupColumns]);
                    return <MTableGroupbar {...props} />;
                },
            }}
            onColumnDragged={(sourceIndex, destinationIndex) => {
                const newColumns = [...currentColumns];
                const [removed] = newColumns.splice(sourceIndex, 1);
                newColumns.splice(destinationIndex, 0, removed);
                saveColumnOrder(newColumns);
            }}
            onChangeColumnHidden={(column, hidden) => {
                const newColumns = [...currentColumns];
                const index = newColumns.findIndex(
                    (col) => col.field === column.field,
                );
                newColumns[index].hidden = hidden;
                saveColumnOrder(newColumns);
            }}
            {...props}
        />
    );
}
