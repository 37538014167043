import { axios } from '@/services/axios';

export const getAllAPIUsers = async (currentCompany) => {
    const res = await axios.get(`/apiusers/${currentCompany}`);
    return res.data;
};

export const createAPIUser = async (apiUser) => {
    const res = await axios.post('/apiuser', apiUser);
    return res.data;
};

export const getAPIUser = async (apiUserId) => {
    const res = await axios.get(`/apiuser/${apiUserId}`);
    return res.data;
};

export const editAPIUser = async (apiUser) => {
    const res = await axios.put('/apiuser', apiUser);
    return res.data;
};

export const deleteAPIUser = async (apiUserId) => {
    const res = await axios.delete(`/apiuser/${apiUserId}`);
    return res.data;
};

export const resetAPIUser = async (apiUserId) => {
    const res = await axios.post(`/apiuser/reset/${apiUserId}`);
    return res.data;
};
