import { Tooltip, Icon } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const FieldToolTip = ({ label, ariaLabel, top, left, bottom }) => {
    const { darkMode } = useSelector((state) => state.settings);
    return (
        <Tooltip hasArrow label={label} aria-label={ariaLabel}>
            <Icon
                color={darkMode ? 'white' : undefined}
                position="relative"
                marginLeft={'-14px !important'}
                top={top}
                left={left}
                bottom={bottom}
            />
        </Tooltip>
    );
};

export default FieldToolTip;
