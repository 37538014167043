import { useState } from 'react';
import { FormControl, Stack, FormLabel, Box, Text } from '@chakra-ui/react';
import { Field } from 'formik';
import PermissionsSetDropDown from '@/components/forms/new/formikFormFields/PermissionSetDropDown';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
    descriptionStyling,
} from '@/components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';

const AdminUsersPermissionSetDropdown = ({
    permissionSetID,
    currentCompany,
    accountScope,
    setAdminUser,
    errors,
    touched,
    darkMode,
    values,
}) => {
    const handleSelectChange = (item, name) => {
        setAdminUser((prev) => ({
            ...prev,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            [name]: item.id,
        }));
    };

    const [isHovered, setIsHovered] = useState(false);

    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.permissionSetID && touched.permissionSetID}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack
                direction={'row'}
                spacing={stackSpacing}
                sx={stackStyling(true)}>
                <Box>
                    <FormLabel sx={formLabelStyling(darkMode)}>
                        Permission Set
                        <span style={{ color: '#e53e3e' }}>*</span>
                    </FormLabel>
                    <Text sx={descriptionStyling} maxWidth={250}>
                        Apply a permission set to the Admin User to customise
                        the management experience.
                    </Text>
                </Box>
                <Field
                    component={PermissionsSetDropDown}
                    id="permissionSetID"
                    name="permissionSetID"
                    permissionSetID={permissionSetID}
                    handleSelectChange={handleSelectChange}
                    currentCompany={currentCompany}
                    accountScope={accountScope}
                    darkMode={darkMode}
                    data-walkthroughid={
                        walkthroughIds.adminsGeneral.ufPermissionSet
                    }
                    validate={() => {
                        if (!permissionSetID) {
                            return 'Please select a permission set';
                        }
                    }}
                />
                {isHovered && (
                    <FieldToolTip
                        label="Permission sets control what an Admin User can and cannot do in TCAP"
                        ariaLabel="admin-user-permission-set-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default AdminUsersPermissionSetDropdown;
