import { Box, VStack, FormErrorMessage } from '@chakra-ui/react';
import { Input } from '@/components/v4';
import { textBoxStyling } from '../formStyles';

const EmailField = ({ field, form: { errors }, ...props }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-start',
                paddingTop: '15px',
            }}>
            <VStack width={'100%'} alignItems={'normal'}>
                <Input
                    {...field}
                    {...props}
                    sx={textBoxStyling}
                    placeholder="eg: abc@outlook.com"
                    data-walkthroughid={props.walkthroughid}
                />
                <FormErrorMessage
                    display="flex"
                    sx={{ marginTop: '0 !important', fontSize: '10px' }}
                    justifyContent="flex-end">
                    {errors.email}
                </FormErrorMessage>
            </VStack>
        </Box>
    );
};

export default EmailField;
