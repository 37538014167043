import { scrollBarStyle } from '@/constants';

export const sectionStackStyling = {
    paddingTop: 8,
    bottomBorder: 'red 1px solid',
};

export const headerStyling = (darkMode) => ({
    font: 'Segoe UI',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '28.8px',
    color: darkMode ? 'white' : '#262626',
});

export const formLabelStyling = (darkMode) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '246px',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    fontWeight: '600',
    paddingTop: '22px',
    lineHeight: '19.2px',
    color: darkMode ? 'white' : null,
    marginBottom: 0,
});

export const descriptionStyling = {
    color: '#757575',
    fontFamily: 'Helvetica',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
};

export const textBoxStyling = {
    height: '30px',
    paddingTop: '15px',
    padding: '2px',
    minWidth: '240px',
    borderRadius: '5px',
};

export const stackStyling = (hasDescription) => {
    return {
        height: hasDescription ? '75px' : '60px',
        width: '775px',
    };
};

export const breadCrumbStyling = {
    fontFamily: 'Helvetica',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0.15px',
    paddingBottom: '15px',
};

export const headingStyling = {
    fontFamily: 'Helvetica',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
    paddingBottom: '15px',
};

export const dropDownFormStyle = (darkMode) => ({
    dropdownIndicator: (provided, { selectProps }) => {
        return {
            ...provided,
            bg: 'transparent',
            px: 2,
            cursor: 'pointer',
            '> svg': {
                transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                transitionDuration: '.4s',
            },
        };
    },
    container: (provided) => ({
        ...provided,
        height: '30px',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        backgroundColor:
            darkMode && isFocused
                ? 'rgba(255, 255, 255, 0.16)!important'
                : null,
    }),
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
        color: `${darkMode ? 'dark.textDark' : 'inherit'}`,
        borderColor: `${darkMode ? 'dark.tcap-borderGray' : 'inherit'}`,
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '30px',
        padding: '0 6px',
        background: `${darkMode ? 'dark.bgDark' : 'inherit'}`,
    }),

    input: (provided) => ({
        ...provided,
        height: '30px',
        margin: '0px',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px',
        background: `${darkMode ? 'dark.bgDark' : 'inherit'}`,
    }),
    inputContainer: (provided) => ({
        ...provided,
        background: `${darkMode ? 'dark.bgDark' : 'inherit'}`,
        color: `${darkMode ? 'dark.textDark' : 'inherit'}`,
    }),
    menuList: (provided, { selectProps }) => ({
        ...provided,
        ...scrollBarStyle(darkMode),
        background: `${
            darkMode && selectProps.onFocus ? 'dark.tcap-weak' : 'white'
        }`,
        borderColor: `${darkMode ? 'dark.tcap-borderGray' : 'inherit'}`,
        color: `${darkMode ? 'white' : 'black'}`,
        zIndex: 1000,
    }),
});

export const stackSpacing = 75;

export const disabledTextColour = '#C8C8C8';

export const borderColor = '#565557';
