import { useState } from 'react';
import {
    FormControl,
    Stack,
    FormLabel,
    Switch,
    Box,
    Text,
} from '@chakra-ui/react';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    formLabelStyling,
    stackStyling,
    stackSpacing,
    descriptionStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../../walkthroughIds';

const AdminUsersGlobalSwitches = ({
    adminUser,
    handleFormSwitchChange,
    darkMode,
    accountScope,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    if (accountScope === 80) {
        return (
            <FormControl
                sx={{ margin: '0 !important' }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <Stack
                    direction={'row'}
                    spacing={stackSpacing}
                    sx={stackStyling(true)}>
                    <Box>
                        <FormLabel
                            sx={{
                                ...formLabelStyling(darkMode),
                                color: '#e53e3e',
                            }}>
                            API Doc Reader
                        </FormLabel>
                        <Text sx={descriptionStyling} maxWidth={250}>
                            For security purposes, it is recommended MFA remains
                            enabled for all Admin Users.
                        </Text>
                    </Box>
                    <Stack direction={'row'} paddingTop="20px">
                        <Stack direction={'row'} spacing={2}>
                            <Switch
                                name="apiDocReader"
                                onChange={handleFormSwitchChange}
                                isChecked={adminUser?.apiDocReader}
                                data-walkthroughid={
                                    walkthroughIds.adminsGeneral.ufApiDocReader
                                }
                            />
                            <Text
                                fontSize="12px"
                                fontWeight={500}
                                color={darkMode ? 'white' : 'black'}>
                                {adminUser?.apiDocReader ? 'On' : 'Off'}
                            </Text>
                        </Stack>
                        {isHovered && (
                            <FieldToolTip
                                label="Grant API Documentaion access to the Admin User"
                                ariaLabel="admin-user-api-doc-reader-tip"
                                left="25px"
                            />
                        )}
                    </Stack>
                </Stack>
            </FormControl>
        );
    } else {
        return null;
    }
};

export default AdminUsersGlobalSwitches;
