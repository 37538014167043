import { useDisclosure } from '@chakra-ui/react';
import { initialState } from '../constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateAPIUser } from '../queries';
import APIKeyModal from '../modals/APIKeyModal';
import moment from 'moment';
import walkthroughIds from '../../walkthroughIds';

import APIUserPermissionSetDropdown from '../formFields/APIUserPermissionSetDropdown';
import APIUserName from '../formFields/APIUserName';
import APIUserExpiration from '../formFields/APIUserExpiration';
import FormBase from 'components/forms/new/FormBase';

export default function ApiUserCreate() {
    const { ufBack, ufCreate } = walkthroughIds.adminApiUsers;
    const { darkMode } = useSelector((state) => state.settings);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const date = new Date();
    const { requiredScope: accountScope } = useSelector((state) => state.login);

    const { currentCompany } = useSelector((state) => state.navigationLists);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [apiKey, setApiKey] = useState('');

    const [apiUser, setApiUser] = useState({
        ...initialState,
        companyID: currentCompany,
    });
    const { mutateAsync: createNewAPIUser } = useCreateAPIUser(
        apiUser,
        setApiKey,
        dispatch,
        onOpen,
    );

    const handleBack = () => {
        navigate('/admins', { state: { index: 1 } });
    };

    const breadCrumbTrail = [
        { label: 'Admin Accounts', path: ['/admins', { index: 1 }] },
        { label: 'Add API User', path: '' },
    ];
    const header = 'API User Details';

    const finalFormSubmit = (value) => {
        if (apiUser.expirationDate.toISOString() > date.toISOString()) {
            let selectedExpiration;
            if (apiUser?.expirationTime) {
                const selectedDate = moment(apiUser.expirationDate);
                selectedDate.set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                });
                selectedExpiration = moment(selectedDate);
            } else {
                const resetTime = moment(apiUser.expirationDate).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                });
                selectedExpiration = moment(resetTime);
            }
            const finalSubmit = {
                ...value,
                expiration: selectedExpiration,
                permissionSetID: apiUser?.permissionSetID,
            };

            setApiKey(finalSubmit);

            createNewAPIUser();
        }
    };

    return (
        <>
            <FormBase
                breadCrumbTrail={breadCrumbTrail}
                header={header}
                initialValues={initialState}
                finalFormSubmit={finalFormSubmit}
                backlink={['/admins', { state: { index: 1 } }]}
                ufBackBtn={ufBack}
                ufSaveBtn={ufCreate}>
                {({ errors, touched, values }) => [
                    <APIUserName
                        key={'apiUserName'}
                        darkMode={darkMode}
                        apiUser={apiUser}
                        errors={errors}
                        touched={touched}
                    />,
                    <APIUserPermissionSetDropdown
                        key={'apiUserPermissionSetDropdown'}
                        permissionSetID={apiUser.permissionSetID}
                        currentCompany={currentCompany}
                        accountScope={accountScope}
                        setApiUser={setApiUser}
                        errors={errors}
                        touched={touched}
                        darkMode={darkMode}
                        values={values}
                    />,
                    <APIUserExpiration
                        key={'apiUserExpiration'}
                        darkMode={darkMode}
                        apiUser={apiUser}
                        setApiUser={setApiUser}
                        errors={errors}
                        touched={touched}
                    />,
                ]}
            </FormBase>
            <APIKeyModal
                apiKey={apiKey}
                setApiKey={setApiKey}
                onClose={onClose}
                isOpen={isOpen}
                handleBack={handleBack}
                darkMode={darkMode}
            />
        </>
    );
}
