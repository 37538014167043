import {
    FormControl,
    InputGroup,
    InputRightElement,
    ModalFooter,
    IconButton,
} from '@chakra-ui/react';
import {
    Text,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from 'components/v4';
import { MdContentCopy } from 'react-icons/md';

import { useState } from 'react';

export default function APIKeyModal({
    apiKey,
    setApiKey,
    isOpen,
    onClose,
    handleBack,
    darkMode,
}) {
    const [copyButton, setCopyButton] = useState({
        text: 'Copy',
        isDisabled: false,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent minW={'30%'} width={422}>
                <ModalHeader fontSize={18} fontWeight={700}>
                    Copy your API key
                </ModalHeader>
                <ModalBody>
                    <Text fontSize={14} fontWeight={400} paddingBottom={30}>
                        Make sure to copy this API Key. You won’t see it again.
                    </Text>
                    <FormControl isReadOnly>
                        <InputGroup>
                            <Input
                                pr="4.5rem"
                                name="apiKey"
                                isReadOnly
                                value={apiKey}
                                overflow="ellipsis"
                            />
                            <InputRightElement width="4.5rem">
                                <IconButton
                                    icon={
                                        <MdContentCopy
                                            color={darkMode ? 'white' : 'black'}
                                        />
                                    }
                                    h="1.75rem"
                                    w="1.75rem"
                                    backgroundColor="transparent"
                                    isDisabled={copyButton.isDisabled}
                                    onClick={() => {
                                        navigator.clipboard.writeText(apiKey);
                                        setCopyButton({
                                            text: 'Copied',
                                            isDisabled: true,
                                        });
                                        setTimeout(() => {
                                            setCopyButton({
                                                text: 'Copy',
                                                isDisabled: false,
                                            });
                                        }, 2000);
                                    }}></IconButton>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        width={121}
                        className="btn btn-primary btn-sm disabled:cursor-not-allowed"
                        onClick={() => {
                            onClose();
                            setApiKey('');
                            handleBack();
                        }}>
                        Done
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
