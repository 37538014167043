import { useQuery, useMutation } from '@tanstack/react-query';
import {
    getAllAPIUsers,
    createAPIUser,
    getAPIUser,
    editAPIUser,
    deleteAPIUser,
    resetAPIUser,
} from 'API/APIKeys';
import { toast } from 'react-toastify';
import moment from 'moment';

export const useGetAllAPIUsers = (currentCompany, setTableData, dispatch) => {
    return useQuery({
        queryKey: ['apiUsers', currentCompany],
        queryFn: async () => {
            const data = await getAllAPIUsers(currentCompany);
            return data;
        },
        onSuccess: async (data) => {
            setTableData((prev) => ({
                ...prev,
                data: data,
            }));
            dispatch({
                type: 'CHANGE_TABLE_LOADING',
            });
        },
        refetchOnWindowFocus: false,
    });
};

export const useCreateAPIUser = (apiUser, setApiKey, dispatch, onOpen) => {
    return useMutation({
        mutationFn: async () => {
            try {
                createAPIUser(apiUser).then((res) => {
                    toast.success('API User created successfully!');
                    dispatch({ type: 'RESET_TABLE' });
                    setApiKey(res.apiKey);
                    onOpen();
                    return res;
                });
            } catch (error) {
                console.log('error now: ', error.message);
                toast.error('error now: ', error.message);
                throw new Error(error?.message || 'Failed to create API User');
            }
        },
    });
};

export const useGetAPIUser = (
    apiUserId,
    setApiUser,
    setLoadingFalse,
    setLaoadingTrue,
    setApiKey,
) => {
    return useQuery({
        queryKey: ['apiUser', { apiUserId }],
        queryFn: async () => {
            try {
                setLaoadingTrue;
                const data = await getAPIUser(apiUserId).then((res) => {
                    const localDateTime = moment.utc(res.expiration).local();
                    setApiUser({
                        ...res,
                        expirationDate: new Date(localDateTime),
                        expirationTime: moment(localDateTime).format('HH:mm'),
                    });
                    setApiKey(res.apiKey);
                    setLoadingFalse;
                    return res;
                });

                return data;
            } catch (error) {
                toast.error(error?.message || 'Failed to fetch API User');
                throw new Error(error?.message || 'Failed to fetch API User');
            }
        },
        enabled: Boolean(apiUserId),
        onError: (err) => {
            toast.error(err?.response?.data?.message);
        },
        refetchOnWindowFocus: false,
    });
};

export const useEditAPIUser = (apiUser, dispatch, navigate) => {
    return useMutation({
        mutationFn: async () => {
            try {
                editAPIUser(apiUser).then(() => {
                    toast.success('API User updated successfully!');
                    dispatch({ type: 'RESET_TABLE' });
                    navigate('/admins', { state: { index: 1 } });
                });
            } catch (error) {
                toast.error(error?.message || 'Failed to update API User');
                throw new Error(error?.message || 'Failed to update API User');
            }
        },
    });
};

export const useDeleteAPIUser = (
    apiUserId,
    dispatch,
    navigate,
    setIsDeleteBusy,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                setIsDeleteBusy(true);
                deleteAPIUser(apiUserId).then(() => {
                    toast.success('API User deleted successfully!');
                    dispatch({
                        type: 'RESET_TABLE',
                    });
                    navigate('/admins', { state: { index: 1 } });
                });
            } catch (error) {
                toast.error(error?.response?.data?.message);
                throw new Error(error?.message || 'Failed to delete API User');
            } finally {
                setIsDeleteBusy(false);
            }
        },
    });
};

export const useResetAPIUser = (
    apiUserId,
    dispatch,
    navigate,
    setIsResetBusy,
    onResetClose,
    setApiKey,
    onApiOpen,
) => {
    return useMutation({
        mutationFn: async () => {
            try {
                setIsResetBusy(true);
                resetAPIUser(apiUserId).then((res) => {
                    dispatch({ type: 'RESET_TABLE' });
                    setApiKey(res.apiKey);
                    onResetClose();
                    onApiOpen();
                    setApiKey(res.apiKey);
                    toast.success('API User reset successfully!');
                });
            } catch (error) {
                toast.error(error?.response?.data?.message);
            } finally {
                setIsResetBusy(false);
            }
        },
    });
};
