import { useState } from 'react';
import { FormControl, Stack, FormLabel, Switch, Text } from '@chakra-ui/react';
import { Button } from '@/components/v4';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    formLabelStyling,
    stackStyling,
} from 'components/forms/new/formStyles';
import walkthroughIds from '../../walkthroughIds';
import { HiOutlineKey } from 'react-icons/hi';
import { FiTrash } from 'react-icons/fi';

const APIUserManageAccess = ({
    apiUser,
    handlFormSwitchChange,
    darkMode,
    onResetOpen,
    onDeleteOpen,
    apiUserId,
}) => {
    const [isActiveHovered, setActiveIsHovered] = useState(false);
    const [isPasswordHovered, setPasswordIsHovered] = useState(false);
    const [isDeleteHovered, setDeleteIsHovered] = useState(false);
    const isDarkmodeColor = darkMode ? '#FFFFFF' : '#000000';

    return apiUserId ? (
        <FormControl sx={{ margin: '0 !important' }}>
            <Stack direction={'row'} height={'35px'} sx={stackStyling()}>
                <FormLabel sx={formLabelStyling(darkMode)}>
                    Manage API User Access
                </FormLabel>
                <Stack direction={'row'} spacing={2} paddingTop={4}>
                    <Stack
                        direction={'row'}
                        sx={{
                            paddingRight: 8,
                            paddingTop: 1,
                            paddingBottom: 6,
                            paddingLeft: '64px',
                        }}
                        onMouseEnter={() => setActiveIsHovered(true)}
                        onMouseLeave={() => setActiveIsHovered(false)}>
                        <Stack direction={'row'} spacing={0}>
                            <Switch
                                paddingLeft={1}
                                paddingRight={'7px'}
                                name="isActive"
                                onChange={handlFormSwitchChange}
                                isChecked={apiUser?.isActive}
                                checked={apiUser?.isActive}
                                walkthroughIds={
                                    walkthroughIds.adminApiUsersGeneral
                                        .ufIsActive
                                }
                            />
                            <Text
                                fontSize="12px"
                                fontWeight={500}
                                color={
                                    !apiUser?.isActive
                                        ? '#737373'
                                        : isDarkmodeColor
                                }>
                                Active
                            </Text>
                        </Stack>
                        {isActiveHovered && (
                            <FieldToolTip
                                label="Activates/Deactivates API User. Inactive users are unable to login"
                                ariaLabel="api-user-active-switch-tip"
                                bottom="2px"
                                left="20px"
                            />
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        fontSize={14}
                        sx={{ paddingRight: 8, paddingBottom: 6 }}
                        onMouseEnter={() => setPasswordIsHovered(true)}
                        onMouseLeave={() => setPasswordIsHovered(false)}>
                        <Button
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersAdmin.ufReset
                            }
                            color={isDarkmodeColor}
                            leftIcon={
                                <HiOutlineKey
                                    fontSize={24}
                                    color={isDarkmodeColor}
                                    style={{ transform: 'scaleX(-1)' }}
                                />
                            }
                            variant="link"
                            fontSize="12px"
                            paddingTop="6px"
                            onClick={onResetOpen}>
                            Reset
                        </Button>
                        {isPasswordHovered && (
                            <FieldToolTip
                                label="Sends password reset link to API User"
                                ariaLabel="admin-user-password-reset-tip"
                                top="2px"
                                left="22px"
                            />
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        onMouseEnter={() => setDeleteIsHovered(true)}
                        onMouseLeave={() => setDeleteIsHovered(false)}
                        sx={{ paddingRight: 10, paddingBottom: 6 }}>
                        <Button
                            leftIcon={
                                <FiTrash
                                    fontSize={24}
                                    color={isDarkmodeColor}
                                />
                            }
                            variant="link"
                            fontSize="12px"
                            paddingTop="6px"
                            data-walkthroughid={
                                walkthroughIds.adminApiUsersAdmin.ufDelete
                            }
                            colorScheme={isDarkmodeColor}
                            onClick={onDeleteOpen}>
                            Delete
                        </Button>
                        {isDeleteHovered && (
                            <FieldToolTip
                                label="Deletes API User"
                                ariaLabel="admin-user-delete-admin-tip"
                                top="2px"
                                left="22px"
                            />
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </FormControl>
    ) : null;
};

export default APIUserManageAccess;
