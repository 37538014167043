/* eslint-disable no-unused-vars */
import {
    Box,
    chakra,
    Checkbox,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { FaSortAmountDown, FaSortAmountUpAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { Card, CardBody } from '@/components/v4';
import EditableCell from './EditableCell';
import MembershipDate from './MembershipDate';
import walkthroughIds from '../../walkthroughIds';

function VgMembersTable({ members, setMembers }) {
    const [sorting, setSorting] = useState([
        {
            id: 'isOwner',
            desc: true,
        },
    ]);
    const [focusedRow, setFocusedRow] = useState(-1);
    const [copy, setCopy] = useState(false);
    const columnHelper = createColumnHelper();
    const { darkMode } = useSelector((state) => state.settings);
    const {
        visibilityGroups: {
            edit: { ufIsOwnerCheckbox },
        },
    } = walkthroughIds;

    /** @const {import('@tanstack/react-table').ColumnDef} */
    const columns = [
        columnHelper.accessor('email', {
            header: 'Email Address',
            cell: EditableCell,
            enableColumnFilter: true,
            filterFn: 'includesString',
            meta: {},
            minSize: 300,
        }),
        columnHelper.accessor('validFrom', {
            header: 'Start',
            cell: (info) => {
                return <MembershipDate {...info} />;
            },
            meta: {},
            size: 180,
        }),
        columnHelper.accessor('validTo', {
            header: 'End',
            cell: (info) => {
                return <MembershipDate {...info} />;
            },
            size: 180,
        }),
        columnHelper.accessor('isOwner', {
            header: 'Is Owner',
            cell: (info) => {
                const { row } = info;
                const rowIndex = row.index;
                const handleChange = (event) => {
                    // Update members data based on checkbox state
                    setMembers((prevMembers) => {
                        const updatedMembers = [...prevMembers];
                        updatedMembers[rowIndex].isOwner = event.target.checked;
                        return updatedMembers;
                    });
                };

                return info?.row.original.email ? (
                    <Checkbox
                        value={info.getValue()}
                        isChecked={row.original.isOwner}
                        onChange={handleChange}
                        data-walkthroughid={ufIsOwnerCheckbox}
                    />
                ) : null;
            },
            meta: {},
            sortDescFirst: true,
            size: 90,
        }),
    ];

    const table = useReactTable({
        columns,
        data: members,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        meta: {
            focusedRow,
            setFocusedRow,
            copy,
            setCopy,
            updateData: (rowIndex, columnId, value, addRow = false) => {
                setMembers((prev) => {
                    const updatedRows = prev.map((row, index) =>
                        index === rowIndex
                            ? {
                                  ...prev[rowIndex],
                                  [columnId]: value,
                                  isOwner:
                                      columnId === 'email' && !value
                                          ? false
                                          : row.isOwner,
                              }
                            : row,
                    );

                    if (addRow) {
                        updatedRows.push({
                            email: '',
                            isOwner: false,
                            validFrom: null,
                            validTo: null,
                        });
                    }

                    return updatedRows;
                });
            },
            deleteRow: (rowIndex) => {
                const newTable = [...members];
                if (newTable.length === 0) {
                    setMembers([{ email: '', isOwner: false }]);
                } else {
                    setMembers((prev) => {
                        const updatedRows = prev.filter(
                            (_, index) => index !== rowIndex,
                        );
                        return updatedRows;
                    });
                }
            },
            updateTable: (rowIndex, columnId, value, setValue) => {
                if (columnId === 'validFrom' || columnId === 'validTo') {
                    console.log(' columnid: ', columnId);
                    const updatedMembers = [...members];
                    updatedMembers[rowIndex] = {
                        ...updatedMembers[rowIndex],
                        [columnId]: value,
                    };
                    setMembers(updatedMembers);
                    setValue(value);
                } else if (columnId === 'email' && copy && value.length > 0) {
                    const inputData = value.trim();
                    const emailRegex =
                        /^(([^\s@]+@[^\s@]+)|([0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}))/;
                    const newEmails = inputData
                        .split(/[\s,;]+/)
                        .filter((email) => emailRegex.test(email));
                    const updatedMembers = [...members];

                    // Replace the current row (empty one) with the first pasted email
                    if (updatedMembers[rowIndex].email === '') {
                        updatedMembers[rowIndex] = {
                            email: newEmails[0],
                            isOwner: false,
                            validFrom: null,
                            validTo: null,
                        };
                    }

                    // Add additional rows for any additional emails
                    const additionalMembers = newEmails
                        .slice(1)
                        .map((email) => ({
                            email,
                            isOwner: false,
                            validFrom: null,
                            validTo: null,
                        }));

                    // Update members list with the new rows
                    const updatedList = [
                        ...updatedMembers,
                        ...additionalMembers,
                    ];
                    setMembers(updatedList);
                    setCopy(false);
                } else {
                    setValue(value);
                    members[rowIndex].email = value;
                    setCopy(false);
                }
            },
        },
        initialState: {
            sorting: [
                {
                    id: 'isOwner',
                    desc: true,
                },
            ],
        },
    });

    if (members === undefined) {
        return <h1>No Table here</h1>;
    }

    return (
        <Card mt="14px" bg={darkMode ? 'dark.tcap' : null} maxW="895px">
            <CardBody>
                <TableContainer
                    width="full"
                    maxW="full"
                    maxHeight="300px"
                    overflowY="auto"
                    sx={{
                        '&': {
                            scrollbarWidth: 'thin',
                        },
                    }}>
                    <Table variant="striped" size="sm" colorScheme="messenger">
                        <Thead
                            position="sticky"
                            top={0}
                            zIndex={10}
                            height="48px"
                            background={darkMode ? 'dark.tcap' : '#F6F6F6'}>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <Tr
                                    key={headerGroup.id}
                                    sx={{
                                        'th:last-of-type': {
                                            textAlign: 'center',
                                        },
                                    }}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <Th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                className="relative"
                                                textTransform="capitalize"
                                                fontSize="sm"
                                                letterSpacing="wide"
                                                paddingInline={
                                                    header.id === 'isOwner'
                                                        ? '16px'
                                                        : null
                                                }
                                                paddingTop="8px"
                                                paddingBottom="8px">
                                                <span
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    className="cursor-pointer">
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </span>
                                                <chakra.span
                                                    pl="2"
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    className="cursor-pointer">
                                                    {header.column.getIsSorted() ? (
                                                        header.column.getIsSorted() ===
                                                        'desc' ? (
                                                            <Icon
                                                                as={
                                                                    FaSortAmountDown
                                                                }
                                                                aria-label="sorted descending"
                                                            />
                                                        ) : (
                                                            <Icon
                                                                as={
                                                                    FaSortAmountUpAlt
                                                                }
                                                                aria-label="sorted ascending"
                                                            />
                                                        )
                                                    ) : null}
                                                </chakra.span>
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Thead>

                        <Tbody>
                            {table.getRowModel().rows.map((row, index) => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                id={cell.id}
                                                style={{
                                                    width: cell.column.getSize(),
                                                }}
                                                className={
                                                    darkMode
                                                        ? index % 2 === 0
                                                            ? '!text-black'
                                                            : '!text-white'
                                                        : 'text-black'
                                                }
                                                sx={{
                                                    '&': {
                                                        button: {
                                                            color: darkMode
                                                                ? index % 2 ===
                                                                  0
                                                                    ? 'black'
                                                                    : 'white'
                                                                : 'black',
                                                        },
                                                    },
                                                    label: {
                                                        marginBottom: 0,
                                                    },
                                                    border: 0,
                                                }}>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent={
                                                        cell.id.includes(
                                                            'isOwner',
                                                        )
                                                            ? 'center'
                                                            : 'flex-start'
                                                    }>
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </Box>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </CardBody>
        </Card>
    );
}
export default VgMembersTable;
