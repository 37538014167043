import { useState } from 'react';
import { FormControl, Stack, FormLabel, Text, Box } from '@chakra-ui/react';
import { Field } from 'formik';
import DatePicker from '@/components/forms/new/formikFormFields/DatePicker';
import FieldToolTip from 'components/forms/new/FieldToolTip';
import {
    stackSpacing,
    formLabelStyling,
    stackStyling,
    descriptionStyling,
} from '@/components/forms/new/formStyles';
import walkthroughIds from '../../walkthroughIds';

const APIUserExpiration = ({
    errors,
    touched,
    darkMode,
    apiUser,
    setApiUser,
}) => {
    const changeHandler = (key, time) => {
        setApiUser({
            ...apiUser,
            expiration: time,
            [key]: time,
        });
    };
    const [isHovered, setIsHovered] = useState(false);
    return (
        <FormControl
            sx={{ margin: '0 !important' }}
            isInvalid={!!errors.expirationDate && touched.expirationDate}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Stack
                direction={'row'}
                spacing={stackSpacing}
                sx={stackStyling(true)}>
                <Box>
                    <FormLabel sx={formLabelStyling(darkMode)}>
                        Expiration <span style={{ color: '#e53e3e' }}>*</span>
                    </FormLabel>
                    <Text sx={descriptionStyling} maxWidth={250}>
                        API token automatically becomes invalid upon this date
                    </Text>
                </Box>
                <Field
                    component={DatePicker}
                    walkthroughid={
                        walkthroughIds.adminApiUsersGeneral.ufExpiration
                    }
                    id="expirationDate"
                    name="expirationDate"
                    darkMode={darkMode}
                    date={apiUser.expirationDate}
                    changeHandler={changeHandler}
                    validate={(value) => {
                        if (!value || value === '') {
                            return 'Expiration is required';
                        }
                        if (value < new Date()) {
                            return 'Expiration date must be in the future';
                        }
                    }}
                />
                {isHovered && (
                    <FieldToolTip
                        label="Select the expiration date for the API User"
                        ariaLabel="api-user-expiration-tip"
                        left="25px"
                        top="25px"
                    />
                )}
            </Stack>
        </FormControl>
    );
};

export default APIUserExpiration;
